import React from 'react';

import * as UI from '..';
import * as Styled from './styled';

import { COLORS } from '../../models/colors';
import { TYPOGRAPHY_SIZE, TYPOGRAPHY_TYPE, BUTTON_TYPE } from '../../models/types';
import { routes, mediaRoutes } from '../../models/routes';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const handleClick = () => {
    window.open('https://app.girlsinsync.com/', '_blank');
  };
  return (
    <Styled.Footer>
      <Styled.Wrapper>
        <Styled.Top>
          <Styled.LogoWrapper>
            <UI.Icon.LogoFooter />
            <Styled.Text>
              <UI.Paragraph size={TYPOGRAPHY_SIZE.EXTRA_SMALL} color={COLORS.WHITE80}>
                The fastest growing platform with AI creators
              </UI.Paragraph>
            </Styled.Text>
            <Styled.WrapperBlockTable>
              <Styled.WrapperLinksTablet>
                {routes.map((i, index) => (
                  <Styled.Link key={index}>
                    <UI.Paragraph type={TYPOGRAPHY_TYPE.MEDIUM} size={TYPOGRAPHY_SIZE.MEDIUM} color={COLORS.WHITE}>
                      {i.label}
                    </UI.Paragraph>
                  </Styled.Link>
                ))}
              </Styled.WrapperLinksTablet>
            </Styled.WrapperBlockTable>
          </Styled.LogoWrapper>
          <Styled.WrapperBlock>
            <Styled.WrapperLinks>
              {routes.map((i, index) => (
                <Styled.Link key={index}>
                  <UI.Paragraph type={TYPOGRAPHY_TYPE.MEDIUM} size={TYPOGRAPHY_SIZE.MEDIUM} color={COLORS.WHITE}>
                    {i.label}
                  </UI.Paragraph>
                </Styled.Link>
              ))}
            </Styled.WrapperLinks>
            <UI.Button type={BUTTON_TYPE.TRANSPARENT} onClick={handleClick}>
              <UI.Paragraph size={TYPOGRAPHY_SIZE.MEDIUM} type={TYPOGRAPHY_TYPE.SEMIBOLD}>
                Become investor
              </UI.Paragraph>
            </UI.Button>
          </Styled.WrapperBlock>
        </Styled.Top>
        <Styled.Bottom>
          <Styled.WrapperMobileMediaLinks>
            {mediaRoutes.map((i, index) => (
              <Styled.Link key={index}>
                <UI.Paragraph size={TYPOGRAPHY_SIZE.EXTRA_SMALL} color={COLORS.WHITE80}>
                  {i.label}
                </UI.Paragraph>
              </Styled.Link>
            ))}
          </Styled.WrapperMobileMediaLinks>
          <UI.Paragraph size={TYPOGRAPHY_SIZE.EXTRA_SMALL} color={COLORS.WHITE80}>
            © {currentYear} GirlsInSync All rights reserved
          </UI.Paragraph>
          <Styled.WrapperDesktopMediaLinks>
            {mediaRoutes.map((i, index) => (
              <Styled.Link key={index}>
                <UI.Paragraph size={TYPOGRAPHY_SIZE.EXTRA_SMALL} color={COLORS.WHITE80}>
                  {i.label}
                </UI.Paragraph>
              </Styled.Link>
            ))}
          </Styled.WrapperDesktopMediaLinks>
        </Styled.Bottom>
      </Styled.Wrapper>
    </Styled.Footer>
  );
};

export default Footer;
