export const routes = [
  { label: 'About us', link: '/' },
  { label: 'Features', link: '/' },
  { label: 'Steps', link: '/' },
  { label: 'Contact Us', link: '/' },
  { label: 'Referral earnings', link: '/' },
];

export const mediaRoutes = [
  { label: 'Privacy Policy', link: '/' },
  { label: 'Terms of Use', link: '/' },
  { label: 'Community Guidelines', link: '/' },
];
