import styled from 'styled-components';

export const H4 = styled.h4`
  font-weight: ${({ weight }) => weight};
  font-style: ${({ fontStyle }) => fontStyle};
  color: ${({ color }) => color};
  font-size: 28px;
  line-height: 30px;
  margin: 0;
  text-transform: uppercase;

  @media (max-width: 1439px) {
    font-size: 24px;
    line-height: 26px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 24px;
  }
`;
