import styled from 'styled-components';

export const H6 = styled.h6`
  font-weight: ${({ weight }) => weight};
  font-style: ${({ fontStyle }) => fontStyle};
  color: ${({ color }) => color};
  font-size: 20px;
  line-height: 22px;
  margin: 0;

  @media (max-width: 1439px) {
  }
  @media (max-width: 767px) {
  }
`;
