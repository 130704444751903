import { COLORS } from '../../../models/colors';
import * as Styled from './styled';

const UserDecline = ({ color, size }) => {
  if (!color) color = COLORS.PINK;
  switch (size) {
    case 'sm':
      return (
        <Styled.Icon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1351_55560)">
            <path
              d="M6.30262 8.22318C6.30262 11.7754 9.18868 14.6615 12.7409 14.6615C16.2932 14.6615 19.1792 11.7754 19.1792 8.22318C19.1792 4.67092 16.2932 1.78486 12.7409 1.78486C9.18868 1.78486 6.30262 4.67092 6.30262 8.22318ZM17.6486 8.22318C17.6486 10.9267 15.4445 13.1309 12.7409 13.1309C10.0374 13.1309 7.83324 10.9267 7.83324 8.22318C7.83324 5.51963 10.0374 3.31549 12.7409 3.31549C15.4445 3.31549 17.6486 5.51963 17.6486 8.22318ZM23.2499 17.2849C22.8405 17.2849 22.4433 17.3248 22.0532 17.3936C20.6623 16.343 18.4925 15.2823 16.6655 15.2823H8.81013C4.38368 15.2823 0.784619 18.8813 0.784619 23.3078V27.003C0.784619 27.2167 0.880894 27.4166 1.02059 27.5572C1.1577 27.6952 1.35113 27.7897 1.55797 27.7683H17.2453C18.458 29.8294 20.6897 31.2155 23.2499 31.2155C27.0936 31.2155 30.2152 28.0938 30.2152 24.2502C30.2152 20.4065 27.0936 17.2849 23.2499 17.2849ZM2.31524 26.2439V23.3078C2.31524 19.7238 5.22618 16.8129 8.81013 16.8129H16.6655C17.73 16.8129 19.0708 17.3301 20.2014 17.9962C17.8854 19.1306 16.2846 21.5041 16.2846 24.2564C16.2846 24.9469 16.3892 25.6111 16.579 26.2439H2.31524ZM23.2499 29.6849C20.255 29.6849 17.8152 27.2451 17.8152 24.2502C17.8152 21.2552 20.255 18.8155 23.2499 18.8155C26.2449 18.8155 28.6846 21.2552 28.6846 24.2502C28.6846 27.2451 26.2449 29.6849 23.2499 29.6849Z"
              fill={color}
              stroke={color}
              strokeWidth="0.290625"
            />
            <path
              d="M26.1979 21.3018C25.9565 21.0604 25.5651 21.0604 25.3237 21.3018L23.2498 23.3757L21.1759 21.3018C20.9345 21.0604 20.5431 21.0604 20.3017 21.3018C20.0603 21.5432 20.0603 21.9346 20.3017 22.176L22.3756 24.2499L20.3017 26.3238C20.0603 26.5652 20.0603 26.9566 20.3017 27.198C20.5431 27.4394 20.9345 27.4394 21.1759 27.198L23.2498 25.1241L25.3237 27.198C25.5651 27.4394 25.9565 27.4394 26.1979 27.198C26.4393 26.9566 26.4393 26.5652 26.1979 26.3238L24.124 24.2499L26.1979 22.176C26.4393 21.9346 26.4393 21.5432 26.1979 21.3018Z"
              fill={color}
            />
          </g>
          <defs>
            <clipPath id="clip0_1351_55560">
              <rect width="31" height="31" fill="white" transform="translate(0 1)" />
            </clipPath>
          </defs>
        </Styled.Icon>
      );
    default:
      return (
        <Styled.Icon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1351_55560)">
            <path
              d="M6.30262 8.22318C6.30262 11.7754 9.18868 14.6615 12.7409 14.6615C16.2932 14.6615 19.1792 11.7754 19.1792 8.22318C19.1792 4.67092 16.2932 1.78486 12.7409 1.78486C9.18868 1.78486 6.30262 4.67092 6.30262 8.22318ZM17.6486 8.22318C17.6486 10.9267 15.4445 13.1309 12.7409 13.1309C10.0374 13.1309 7.83324 10.9267 7.83324 8.22318C7.83324 5.51963 10.0374 3.31549 12.7409 3.31549C15.4445 3.31549 17.6486 5.51963 17.6486 8.22318ZM23.2499 17.2849C22.8405 17.2849 22.4433 17.3248 22.0532 17.3936C20.6623 16.343 18.4925 15.2823 16.6655 15.2823H8.81013C4.38368 15.2823 0.784619 18.8813 0.784619 23.3078V27.003C0.784619 27.2167 0.880894 27.4166 1.02059 27.5572C1.1577 27.6952 1.35113 27.7897 1.55797 27.7683H17.2453C18.458 29.8294 20.6897 31.2155 23.2499 31.2155C27.0936 31.2155 30.2152 28.0938 30.2152 24.2502C30.2152 20.4065 27.0936 17.2849 23.2499 17.2849ZM2.31524 26.2439V23.3078C2.31524 19.7238 5.22618 16.8129 8.81013 16.8129H16.6655C17.73 16.8129 19.0708 17.3301 20.2014 17.9962C17.8854 19.1306 16.2846 21.5041 16.2846 24.2564C16.2846 24.9469 16.3892 25.6111 16.579 26.2439H2.31524ZM23.2499 29.6849C20.255 29.6849 17.8152 27.2451 17.8152 24.2502C17.8152 21.2552 20.255 18.8155 23.2499 18.8155C26.2449 18.8155 28.6846 21.2552 28.6846 24.2502C28.6846 27.2451 26.2449 29.6849 23.2499 29.6849Z"
              fill={color}
              stroke={color}
              strokeWidth="0.290625"
            />
            <path
              d="M26.1979 21.3018C25.9565 21.0604 25.5651 21.0604 25.3237 21.3018L23.2498 23.3757L21.1759 21.3018C20.9345 21.0604 20.5431 21.0604 20.3017 21.3018C20.0603 21.5432 20.0603 21.9346 20.3017 22.176L22.3756 24.2499L20.3017 26.3238C20.0603 26.5652 20.0603 26.9566 20.3017 27.198C20.5431 27.4394 20.9345 27.4394 21.1759 27.198L23.2498 25.1241L25.3237 27.198C25.5651 27.4394 25.9565 27.4394 26.1979 27.198C26.4393 26.9566 26.4393 26.5652 26.1979 26.3238L24.124 24.2499L26.1979 22.176C26.4393 21.9346 26.4393 21.5432 26.1979 21.3018Z"
              fill={color}
            />
          </g>
          <defs>
            <clipPath id="clip0_1351_55560">
              <rect width="31" height="31" fill="white" transform="translate(0 1)" />
            </clipPath>
          </defs>
        </Styled.Icon>
      );
  }
};

export default UserDecline;
