import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import * as TymioUI from './components/index.js';

const Layout = lazy(() => import('./components/Layout/Layout.jsx'));
const Main = lazy(() => import('./pages/Main/Main.jsx'));
const UIPage = lazy(() => import('./pages/UI/UI.jsx'));

const App = () => {
  return (
    <>
      <TymioUI.Modal />
      <TymioUI.MessageDialog />
      <Suspense fallback={<></>}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Main />} />
            <Route path="*" element={<Navigate to="/" replace />} />
            <Route path="/ui" element={<UIPage />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
