import styled from 'styled-components';

export const H5 = styled.h5`
  font-weight: ${({ weight }) => weight};
  font-style: ${({ fontStyle }) => fontStyle};
  color: ${({ color }) => color};
  font-size: 22px;
  line-height: 24px;
  margin: 0;

  @media (max-width: 1439px) {
    font-size: 22px;
    line-height: 24px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 22px;
  }
`;
