import { COLORS } from '../../../models/colors';
import * as Styled from './styled';

const Switcher = ({ color, size }) => {
  if (!color) color = COLORS.PINK;
  switch (size) {
    case 'sm':
      return (
        <Styled.Icon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M23.1724 11.0344C24.3912 11.0344 25.3793 10.0463 25.3793 8.8275C25.3793 7.60867 24.3912 6.62061 23.1724 6.62061C21.9536 6.62061 20.9655 7.60867 20.9655 8.8275C20.9655 10.0463 21.9536 11.0344 23.1724 11.0344Z"
            fill={color}
          />
          <path
            d="M23.1724 15.4484H8.8276C5.17629 15.4484 2.20691 12.479 2.20691 8.82772C2.20691 5.17641 5.17629 2.20703 8.8276 2.20703H23.1724C26.8237 2.20703 29.7931 5.17641 29.7931 8.82772C29.7931 12.479 26.8237 15.4484 23.1724 15.4484ZM8.8276 4.41393C6.39339 4.41393 4.41381 6.39351 4.41381 8.82772C4.41381 11.2619 6.39339 13.2415 8.8276 13.2415H23.1724C25.6066 13.2415 27.5862 11.2619 27.5862 8.82772C27.5862 6.39351 25.6066 4.41393 23.1724 4.41393H8.8276Z"
            fill={color}
          />
          <path
            d="M8.82756 25.3791C10.0464 25.3791 11.0345 24.3911 11.0345 23.1722C11.0345 21.9534 10.0464 20.9653 8.82756 20.9653C7.60873 20.9653 6.62067 21.9534 6.62067 23.1722C6.62067 24.3911 7.60873 25.3791 8.82756 25.3791Z"
            fill={color}
          />
          <path
            d="M23.1724 29.7931H8.8276C5.17629 29.7931 2.20691 26.8238 2.20691 23.1724C2.20691 19.5211 5.17629 16.5518 8.8276 16.5518H23.1724C26.8237 16.5518 29.7931 19.5211 29.7931 23.1724C29.7931 26.8238 26.8237 29.7931 23.1724 29.7931ZM8.8276 18.7587C6.39339 18.7587 4.41381 20.7382 4.41381 23.1724C4.41381 25.6067 6.39339 27.5862 8.8276 27.5862H23.1724C25.6066 27.5862 27.5862 25.6067 27.5862 23.1724C27.5862 20.7382 25.6066 18.7587 23.1724 18.7587H8.8276Z"
            fill={color}
          />
        </Styled.Icon>
      );
    default:
      return (
        <Styled.Icon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M23.1724 11.0344C24.3912 11.0344 25.3793 10.0463 25.3793 8.8275C25.3793 7.60867 24.3912 6.62061 23.1724 6.62061C21.9536 6.62061 20.9655 7.60867 20.9655 8.8275C20.9655 10.0463 21.9536 11.0344 23.1724 11.0344Z"
            fill={color}
          />
          <path
            d="M23.1724 15.4484H8.8276C5.17629 15.4484 2.20691 12.479 2.20691 8.82772C2.20691 5.17641 5.17629 2.20703 8.8276 2.20703H23.1724C26.8237 2.20703 29.7931 5.17641 29.7931 8.82772C29.7931 12.479 26.8237 15.4484 23.1724 15.4484ZM8.8276 4.41393C6.39339 4.41393 4.41381 6.39351 4.41381 8.82772C4.41381 11.2619 6.39339 13.2415 8.8276 13.2415H23.1724C25.6066 13.2415 27.5862 11.2619 27.5862 8.82772C27.5862 6.39351 25.6066 4.41393 23.1724 4.41393H8.8276Z"
            fill={color}
          />
          <path
            d="M8.82756 25.3791C10.0464 25.3791 11.0345 24.3911 11.0345 23.1722C11.0345 21.9534 10.0464 20.9653 8.82756 20.9653C7.60873 20.9653 6.62067 21.9534 6.62067 23.1722C6.62067 24.3911 7.60873 25.3791 8.82756 25.3791Z"
            fill={color}
          />
          <path
            d="M23.1724 29.7931H8.8276C5.17629 29.7931 2.20691 26.8238 2.20691 23.1724C2.20691 19.5211 5.17629 16.5518 8.8276 16.5518H23.1724C26.8237 16.5518 29.7931 19.5211 29.7931 23.1724C29.7931 26.8238 26.8237 29.7931 23.1724 29.7931ZM8.8276 18.7587C6.39339 18.7587 4.41381 20.7382 4.41381 23.1724C4.41381 25.6067 6.39339 27.5862 8.8276 27.5862H23.1724C25.6066 27.5862 27.5862 25.6067 27.5862 23.1724C27.5862 20.7382 25.6066 18.7587 23.1724 18.7587H8.8276Z"
            fill={color}
          />
        </Styled.Icon>
      );
  }
};

export default Switcher;
