import styled from 'styled-components';

export const Icon = styled.svg`
	width: 32px;
	height: 32px;

  @media (max-width: 1439px) {
    width: 28px;
	height: 28px;
  }

  @media (max-width: 767px) {
	width: 24px;
	height: 24px;
  }
`;