import styled from 'styled-components';
import { COLORS } from '../../models/colors';

export const Footer = styled.footer`
  background-color: ${COLORS.BLACK};
  width: 100%;
  padding: 24px 100px 28px 100px;
  display: flex;
  justify-content: center;
  @media (max-width: 1439px) {
    padding: 32px 24px 18px 24px;
  }

  @media (max-width: 767px) {
    padding: 24px 12px 16px 12px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-basis: 1240px;
  flex-direction: column;
  @media (max-width: 767px) {
    width: 100%;
    flex-basis: auto;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 1439px) {
    svg {
      width: 130px;
      height: 40px;
    }
  }
  @media (max-width: 767px) {
    gap: 12px;
  }
`;

export const Text = styled.div`
  @media (max-width: 1439px) {
    p {
      color: #9e9e9e;
    }
  }
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const WrapperLinks = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 16px;
  gap: 40px;
  @media (max-width: 1439px) {
    display: none;
  }
  @media (max-width: 767px) {
    display: flex;
    padding-top: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

export const WrapperLinksTablet = styled.div`
  display: none;

  @media (max-width: 1439px) {
    display: flex;
    align-items: flex-start;
    padding-top: 16px;
    gap: 40px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

export const WrapperBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-end;
  @media (max-width: 767px) {
    align-items: flex-start;
    gap: 24px;
    button {
      width: 100%;
    }
  }
`;

export const Top = styled.div`
  border-bottom: 1px solid ${COLORS.BODY};
  padding-bottom: 24px;
  margin-bottom: 24px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 100px;
  @media (max-width: 767px) {
    gap: 0;
    flex-direction: column;
  }
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
  @media (max-width: 767px) {
    align-items: flex-start;
    gap: 24px;
    flex-direction: column;
  }
`;

export const WrapperDesktopMediaLinks = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  @media (max-width: 767px) {
    display: none;
  }
`;
export const WrapperMobileMediaLinks = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
  }
`;

export const WrapperBlockTable = styled.div`
  display: none;

  @media (max-width: 1439px) {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: flex-end;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;
