import styled from 'styled-components';

export const H1 = styled.h1`
  font-weight: ${({ weight }) => weight};
  font-style: ${({ fontStyle }) => fontStyle};
  color: ${({ color }) => color};
  font-size: 48px;
  line-height: 52px;
  margin: 0;
  text-transform: uppercase;
  
  @media (max-width: 1439px) {
    font-size: 40px;
    line-height: 44px;
  }
  @media (max-width: 767px) {
    font-size: 26px;
    line-height: 28px;
  }
`;
