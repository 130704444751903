//Typography
import H1 from './Typography/H1/H1';
import H2 from './Typography/H2/H2';
import H3 from './Typography/H3/H3';
import H4 from './Typography/H4/H4';
import H5 from './Typography/H5/H5';
import H6 from './Typography/H6/H6';
import Paragraph from './Typography/Paragraph/Paragraph';

//Components
import Button from './Button/Button';
import Menu from './Menu';
import AccordionCases from './AccordionCases/AccordionCases';
import Accordion from './Accordion/Accordion';
import Input from './Input/Input';
import Card from './Card/Card';
import Header from './Header/Header';
import Badge from './Badge/Badge';
import Dropdown from './Dropdown/Dropdown';
import Footer from './Footer/Footer';
import Layout from './Layout/Layout';
import Animation from './Animation/Animation';
import Tooltip from './Tooltip/Tooltip';
import List from './List';
import Table from './Table';
import Modal from './Modal/Modal';
import MessageDialog from './MessageDialog/MessageDialog';
import Link from './Link/Link';
import VerticalSlider from './VerticalSlider/VerticalSlider';
import LoadingSpinner from './LoadingSpinner/LoadingSpinner';
import Message from './Message/Message';
import MenuLink from './MenuLink/MenuLink';
import ArticleLink from './ArticleLink/ArticleLink';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import { PinkDecoration } from './PinkDecoration/PinkDecoration';
import { HR } from './HR/styled'
import { Grid, GridElem } from './Grid/styled';
import { Container } from './Container/styled';

// Icons
import Check from './Icons/Check/Check';
import DoorArrow from './Icons/DoorArrow/DoorArrow';
import HeartLock from './Icons/HeartLock/HeartLock';
import Mingcute from './Icons/Mingcute/Mingcute';
import Picture from './Icons/Picture/Picture';
import Switcher from './Icons/Switcher/Switcher';
import Typing from './Icons/Typing/Typing';
import User from './Icons/User/User';
import UserDecline from './Icons/UserDecline/UserDecline';
import Logo from './Icons/Logo/Logo';
import Cross from './Icons/Cross/Cross';
import Burger from './Icons/Burger/Burger';
import LogoFooter from './Icons/LogoFooter/LogoFooter';

const Icon = {
  Check,
  DoorArrow,
  HeartLock,
  Mingcute,
  Picture,
  Switcher,
  Typing,
  User,
  UserDecline,
  Logo,
  Cross,
  Burger,
  LogoFooter,
};

export {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Paragraph,
  Button,
  Menu,
  AccordionCases,
  Accordion,
  Input,
  Card,
  Header,
  Badge,
  Dropdown,
  Footer,
  Layout,
  Animation,
  Tooltip,
  List,
  Table,
  Modal,
  MessageDialog,
  Link,
  VerticalSlider,
  LoadingSpinner,
  Message,
  MenuLink,
  ArticleLink,
  Breadcrumbs,
  Grid,
  GridElem,
  Container,
  Icon,
  PinkDecoration,
  HR
};
