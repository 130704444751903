import React from 'react';
import * as Styled from './styled';
import { HH_TYPE } from '../../../models/types';

export const H2 = ({ children, noMedia, ...props }) => {
  if (!props.type) props.type = HH_TYPE.EXTRABOLD_ITALIC;

  if (!props.weight) props.weight = props.type.weight;
  if (!props.fontStyle) props.fontStyle = props.type.style;

  return (
    <Styled.H2 noMedia={noMedia} {...props}>
      {children}
    </Styled.H2>
  );
};

export default H2;
