import React from 'react';
import * as Styled from './styled';
import { TYPOGRAPHY_TYPE } from '../../../models/types';

export const Paragraph = ({ children, size, lh, ...props }) => {
  if (!props.type) props.type = TYPOGRAPHY_TYPE.SEMIBOLD;

  if (!props.weight) props.weight = props.type.weight;
  if (!props.fontStyle) props.fontStyle = props.type.style;

  return (
    <Styled.Paragraph size={size} lh={lh} {...props}>
      {children}
    </Styled.Paragraph>
  );
};

export default Paragraph;
