import styled from 'styled-components';

const sizeMap = {
  lg: {
    fontSize: '18px',
    lineHeight: '23px',
    tablet: {
      fontSize: '16px',
      lineHeight: '20px',
    },
    mobile: {
      fontSize: '16px',
      lineHeight: '20px',
    }
  },
  md: {
    fontSize: '16px',
    lineHeight: '20px',
    tablet: {
      fontSize: '14px',
      lineHeight: '18px',
    },
    mobile: {
      fontSize: '14px',
      lineHeight: '18px',
    }
  },
  sm: {
    fontSize: '15px',
    lineHeight: '20px',
    tablet: {
      fontSize: '14px',
      lineHeight: '18px',
    },
    mobile: {
      fontSize: '14px',
      lineHeight: '18px',
    }
  },
  xs: {
    fontSize: '14px',
    lineHeight: '16px',
    tablet: {
      fontSize: '14px',
      lineHeight: '16px',
    },
    mobile: {
      fontSize: '14px',
      lineHeight: '16px',
    }
  },
};

export const Paragraph = styled.p`
  font-weight: ${({ weight }) => weight};
  font-style: ${({ fontStyle }) => fontStyle};
  color: ${({ color }) => color};

  margin: 0;
  font-size: ${({ size }) =>
    sizeMap[size]?.fontSize || sizeMap['md'].fontSize};
  line-height: ${({ size, lh }) =>
    lh || sizeMap[size]?.lineHeight || sizeMap['md'].lineHeight};
  text-transform: ${({ uppercase }) => uppercase && 'uppercase '};
  text-align: ${({ align }) => align && align};

  @media (max-width: 1439px) {
    font-size: ${({ size }) =>
    sizeMap[size]?.tablet?.fontSize || sizeMap['md'].fontSize};
    line-height: ${({ size, lh }) =>
    lh || sizeMap[size]?.tablet?.lineHeight || sizeMap['md'].lineHeight};
  }
  @media (max-width: 767px) {
    font-size: ${({ size }) =>
    sizeMap[size]?.mobile?.fontSize || sizeMap['md'].fontSize};
    line-height: ${({ size, lh }) =>
    lh || sizeMap[size]?.mobile?.lineHeight || sizeMap['md'].lineHeight};
  }
`;
