export const ROUND = {
  TOP: 'top',
  BOTTOM: 'bottom',
  ALL: 'all',
  NONE: 'none',
};

export const BACKGROUND_TYPE = {
  DOUBLE: 'double',
};

export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  TRANSPARENT: 'transparent',
  TRANSPARENT_BLACK: 'transparent_black',
};

export const TYPOGRAPHY_SIZE = {
  LARGE: 'lg',
  MEDIUM: 'md',
  SMALL: 'sm',
  EXTRA_SMALL: 'xs',
};

export const ICON_SIZE = {
  LARGE: 'lg',
  MEDIUM: 'md',
  SMALL: 'sm',
};

export const HH_TYPE = {
  EXTRABOLD_ITALIC: {
    weight: 800,
    style: 'italic',
  },
  BOLD: {
    weight: 700,
    style: 'normal',
  },
  BOLD_ITALIC: {
    weight: 700,
    style: 'italic',
  },
  SEMIBOLD: {
    weight: 600,
    style: 'normal',
  },
};

export const TYPOGRAPHY_TYPE = {
  BOLD: {
    weight: 600,
    style: 'normal',
  },
  SEMIBOLD: {
    weight: 700,
    style: 'normal',
  },
  MEDIUM: {
    weight: 500,
    style: 'normal',
  },
  SEMIBOLD_ITALIC: {
    weight: 600,
    style: 'italic',
  },
};
