import React from 'react';
import { Outlet } from 'react-router-dom';
import * as UI from '../index';
import * as Styled from './styled';

const Layout = () => {
  return (
    <Styled.PageWrapper>
      <UI.Header />
      <Styled.Content>
        <Styled.Main>
          <Outlet />
        </Styled.Main>
      </Styled.Content>
      <UI.Footer />
    </Styled.PageWrapper>
  );
};

export default Layout;
