import styled from 'styled-components';
import { COLORS } from '../../models/colors';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  background-color: ${COLORS.WHITE};
`;

export const Content = styled.div`
  flex: 1;
`;

export const Main = styled.main`
  min-height: 100vh;
`;
