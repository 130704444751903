import styled from 'styled-components';

export const H3 = styled.h3`
  font-weight: ${({ weight }) => weight};
  font-style: ${({ fontStyle }) => fontStyle};
  color: ${({ color }) => color};
  font-size: 40px;
  line-height: 40px;
  margin: 0;

  span {
    font-weight: unset;
  }

  @media (max-width: 1439px) {
    font-size: 28px;
    line-height: 28px;
  }
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 24px;
  }
`;
