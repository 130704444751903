import { COLORS } from '../../../models/colors';

const User = ({ color, size }) => {
  if (!color) color = COLORS.WHITE;
  switch (size) {
    case 'sm':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.375 6.625C12.375 5.31265 11.3124 4.25 10 4.25C8.68767 4.25 7.62502 5.31265 7.62502 6.625C7.62502 7.93735 8.68767 9 10 9C11.3124 9 12.375 7.93735 12.375 6.625ZM13.875 6.625C13.875 8.76578 12.1408 10.5 10 10.5C7.85924 10.5 6.12502 8.76578 6.12502 6.625C6.12502 4.48422 7.85924 2.75 10 2.75C12.1408 2.75 13.875 4.48422 13.875 6.625ZM4.91669 15.3571C4.91669 15.9978 4.84105 15.9167 5.1961 15.9167H14.8039C15.159 15.9167 15.0834 15.9978 15.0834 15.3571C15.0834 13.78 12.7682 12.8333 10 12.8333C7.23185 12.8333 4.91669 13.78 4.91669 15.3571ZM3.41669 15.3571C3.41669 12.5848 6.47734 11.3333 10 11.3333C13.5227 11.3333 16.5834 12.5848 16.5834 15.3571C16.5834 16.8035 16.0121 17.4167 14.8039 17.4167H5.1961C3.98797 17.4167 3.41669 16.8035 3.41669 15.3571Z"
            fill={color}
          />
        </svg>
      );
    default:
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.375 6.625C12.375 5.31265 11.3124 4.25 10 4.25C8.68767 4.25 7.62502 5.31265 7.62502 6.625C7.62502 7.93735 8.68767 9 10 9C11.3124 9 12.375 7.93735 12.375 6.625ZM13.875 6.625C13.875 8.76578 12.1408 10.5 10 10.5C7.85924 10.5 6.12502 8.76578 6.12502 6.625C6.12502 4.48422 7.85924 2.75 10 2.75C12.1408 2.75 13.875 4.48422 13.875 6.625ZM4.91669 15.3571C4.91669 15.9978 4.84105 15.9167 5.1961 15.9167H14.8039C15.159 15.9167 15.0834 15.9978 15.0834 15.3571C15.0834 13.78 12.7682 12.8333 10 12.8333C7.23185 12.8333 4.91669 13.78 4.91669 15.3571ZM3.41669 15.3571C3.41669 12.5848 6.47734 11.3333 10 11.3333C13.5227 11.3333 16.5834 12.5848 16.5834 15.3571C16.5834 16.8035 16.0121 17.4167 14.8039 17.4167H5.1961C3.98797 17.4167 3.41669 16.8035 3.41669 15.3571Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default User;
