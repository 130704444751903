import React from 'react';

import * as Styled from './styled';
import * as UI from '../index';
import { COLORS } from '../../models/colors';

import { TYPOGRAPHY_SIZE, TYPOGRAPHY_TYPE } from '../../models/types';

const Badge = ({ children, light }) => {
  return (
    <Styled.Badge light={light}>
      <UI.Paragraph
        size={TYPOGRAPHY_SIZE.SMALL}
        type={TYPOGRAPHY_TYPE.SEMIBOLD_ITALIC}
        color={light ? COLORS.WHITE80 : COLORS.BODY}
      >
        {children}
      </UI.Paragraph>
    </Styled.Badge>
  );
};

export default Badge;
