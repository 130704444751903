import React from 'react';
import * as Styled from './styled';
import { HH_TYPE } from '../../../models/types';

export const H3 = ({ children, ...props }) => {
  if (!props.type) props.type = HH_TYPE.EXTRABOLD_ITALIC;

  if (!props.weight) props.weight = props.type.weight;
  if (!props.fontStyle) props.fontStyle = props.type.style;

  return <Styled.H3 {...props}>{children}</Styled.H3>;
};

export default H3;
