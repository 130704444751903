import styled from 'styled-components';
import { COLORS } from '../../models/colors';
import { BUTTON_TYPE } from '../../models/types';

export const Button = styled.button`
  border-radius: 100px;
  text-transform: uppercase;
  border: none;
  width: fit-content;
  cursor: pointer;
  padding: 20px 56px;
  min-width: 228px;
  text-transform: uppercase;

  background: ${COLORS.PINK};
  color: ${COLORS.WHITE};

  &:hover {
    background: ${COLORS.PINK_HOVER};
    color: ${COLORS.WHITE};
  }

  &:focus {
  }

  &:disabled {
    opacity: 0.7;
    background: ${COLORS.PINK};
    color: ${COLORS.WHITE};
  }

  @media (max-width: 1439px) {
    padding: 14px 56px;
    min-width: 332px;
  }
  @media (max-width: 767px) {
    padding: 12px 56px;
    min-width: 336px;
  }

  ${({ type }) => {
    switch (type) {
      case BUTTON_TYPE.SECONDARY:
        return `
          background: ${COLORS.WHITE};
          color: ${COLORS.PINK};

          &:hover {
            background: ${COLORS.WHITE};
            color: ${COLORS.PINK_HOVER};
          }

          &:disabled {
            background: ${COLORS.WHITE};
            color: ${COLORS.PINK}; 
          }
        `;

      case BUTTON_TYPE.TRANSPARENT:
        return `
          padding: 10px 24px;
          background: ${COLORS.TRANSPARENT};
          min-width: 130px;
          box-shadow: 0 0 0 1px ${COLORS.WHITE};

          &:hover {
            border: none;
            background: ${COLORS.WHITE};
            color: ${COLORS.PINK};
            path {
              fill: ${COLORS.PINK};
            }
          }

          &:disabled {
            box-shadow: 0 0 0 1px ${COLORS.WHITE};
            background: ${COLORS.TRANSPARENT};
            color: ${COLORS.WHITE};
            path {
              fill: ${COLORS.WHITE};
            }
          }

          @media (max-width: 1439px) {
            padding: 10px 24px;
            min-width: 130px;
          }
          @media (max-width: 767px) {
            padding: 10px 24px;
            min-width: 130px;
          }
        `;

      case BUTTON_TYPE.TRANSPARENT_BLACK:
        return `
          padding: 10px 24px;
          background: ${COLORS.TRANSPARENT};
          min-width: 130px;
          box-shadow: 0 0 0 1px ${COLORS.BLACK};
          color: ${COLORS.BLACK};
          path {
            fill: ${COLORS.BLACK};
          }

          &:hover {
            border: unset;
            background: unset;
            color: unset;
          }

          &:disabled {
            border: unset;
            background: unset;
            color: unset;
          }

          @media (max-width: 1439px) {
            padding: 10px 24px;
            min-width: 130px;
          }
          @media (max-width: 767px) {
            padding: 10px 24px;
            min-width: 130px;
          }
        `;

      case BUTTON_TYPE.TERTIARY:
        return `
          background: ${COLORS.VIOLET};

          &:hover {
            background: ${COLORS.VIOLET_HOVER};
          }

          &:disabled {
            background: ${COLORS.VIOLET};
          }
        `;
      default:
        return;
    }
  }}
`;
