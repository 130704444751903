import { COLORS } from '../../../models/colors';

const Mingcute = ({ color, size }) => {
  if (!color) color = COLORS.WHITE;
  switch (size) {
    case 'sm':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1351_53696)">
            <path
              d="M10 2.5C10.2041 2.50003 10.4011 2.57496 10.5536 2.7106C10.7062 2.84623 10.8036 3.03312 10.8275 3.23583L10.8333 3.33333V16.6667C10.8331 16.8791 10.7518 17.0834 10.606 17.2378C10.4602 17.3923 10.2609 17.4852 10.0488 17.4976C9.8368 17.5101 9.62802 17.4411 9.46514 17.3048C9.30226 17.1685 9.19759 16.9751 9.1725 16.7642L9.16667 16.6667V3.33333C9.16667 3.11232 9.25446 2.90036 9.41074 2.74408C9.56702 2.5878 9.77899 2.5 10 2.5ZM6.66667 5C6.88768 5 7.09964 5.0878 7.25592 5.24408C7.4122 5.40036 7.5 5.61232 7.5 5.83333V14.1667C7.5 14.3877 7.4122 14.5996 7.25592 14.7559C7.09964 14.9122 6.88768 15 6.66667 15C6.44565 15 6.23369 14.9122 6.07741 14.7559C5.92113 14.5996 5.83333 14.3877 5.83333 14.1667V5.83333C5.83333 5.61232 5.92113 5.40036 6.07741 5.24408C6.23369 5.0878 6.44565 5 6.66667 5ZM13.3333 5C13.5543 5 13.7663 5.0878 13.9226 5.24408C14.0789 5.40036 14.1667 5.61232 14.1667 5.83333V14.1667C14.1667 14.3877 14.0789 14.5996 13.9226 14.7559C13.7663 14.9122 13.5543 15 13.3333 15C13.1123 15 12.9004 14.9122 12.7441 14.7559C12.5878 14.5996 12.5 14.3877 12.5 14.1667V5.83333C12.5 5.61232 12.5878 5.40036 12.7441 5.24408C12.9004 5.0878 13.1123 5 13.3333 5ZM3.33333 7.5C3.55435 7.5 3.76631 7.5878 3.92259 7.74408C4.07887 7.90036 4.16667 8.11232 4.16667 8.33333V11.6667C4.16667 11.8877 4.07887 12.0996 3.92259 12.2559C3.76631 12.4122 3.55435 12.5 3.33333 12.5C3.11232 12.5 2.90036 12.4122 2.74408 12.2559C2.5878 12.0996 2.5 11.8877 2.5 11.6667L2.5 8.33333C2.5 8.11232 2.5878 7.90036 2.74408 7.74408C2.90036 7.5878 3.11232 7.5 3.33333 7.5ZM16.6667 7.5C16.8708 7.50003 17.0678 7.57496 17.2203 7.7106C17.3728 7.84623 17.4703 8.03312 17.4942 8.23583L17.5 8.33333V11.6667C17.4998 11.8791 17.4184 12.0834 17.2726 12.2378C17.1268 12.3923 16.9275 12.4852 16.7155 12.4976C16.5035 12.5101 16.2947 12.4411 16.1318 12.3048C15.9689 12.1685 15.8643 11.9751 15.8392 11.7642L15.8333 11.6667V8.33333C15.8333 8.11232 15.9211 7.90036 16.0774 7.74408C16.2337 7.5878 16.4457 7.5 16.6667 7.5Z"
              fill={color}
            />
          </g>
          <defs>
            <clipPath id="clip0_1351_53696">
              <rect width="20" height="20" fill={color} />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1351_53696)">
            <path
              d="M10 2.5C10.2041 2.50003 10.4011 2.57496 10.5536 2.7106C10.7062 2.84623 10.8036 3.03312 10.8275 3.23583L10.8333 3.33333V16.6667C10.8331 16.8791 10.7518 17.0834 10.606 17.2378C10.4602 17.3923 10.2609 17.4852 10.0488 17.4976C9.8368 17.5101 9.62802 17.4411 9.46514 17.3048C9.30226 17.1685 9.19759 16.9751 9.1725 16.7642L9.16667 16.6667V3.33333C9.16667 3.11232 9.25446 2.90036 9.41074 2.74408C9.56702 2.5878 9.77899 2.5 10 2.5ZM6.66667 5C6.88768 5 7.09964 5.0878 7.25592 5.24408C7.4122 5.40036 7.5 5.61232 7.5 5.83333V14.1667C7.5 14.3877 7.4122 14.5996 7.25592 14.7559C7.09964 14.9122 6.88768 15 6.66667 15C6.44565 15 6.23369 14.9122 6.07741 14.7559C5.92113 14.5996 5.83333 14.3877 5.83333 14.1667V5.83333C5.83333 5.61232 5.92113 5.40036 6.07741 5.24408C6.23369 5.0878 6.44565 5 6.66667 5ZM13.3333 5C13.5543 5 13.7663 5.0878 13.9226 5.24408C14.0789 5.40036 14.1667 5.61232 14.1667 5.83333V14.1667C14.1667 14.3877 14.0789 14.5996 13.9226 14.7559C13.7663 14.9122 13.5543 15 13.3333 15C13.1123 15 12.9004 14.9122 12.7441 14.7559C12.5878 14.5996 12.5 14.3877 12.5 14.1667V5.83333C12.5 5.61232 12.5878 5.40036 12.7441 5.24408C12.9004 5.0878 13.1123 5 13.3333 5ZM3.33333 7.5C3.55435 7.5 3.76631 7.5878 3.92259 7.74408C4.07887 7.90036 4.16667 8.11232 4.16667 8.33333V11.6667C4.16667 11.8877 4.07887 12.0996 3.92259 12.2559C3.76631 12.4122 3.55435 12.5 3.33333 12.5C3.11232 12.5 2.90036 12.4122 2.74408 12.2559C2.5878 12.0996 2.5 11.8877 2.5 11.6667L2.5 8.33333C2.5 8.11232 2.5878 7.90036 2.74408 7.74408C2.90036 7.5878 3.11232 7.5 3.33333 7.5ZM16.6667 7.5C16.8708 7.50003 17.0678 7.57496 17.2203 7.7106C17.3728 7.84623 17.4703 8.03312 17.4942 8.23583L17.5 8.33333V11.6667C17.4998 11.8791 17.4184 12.0834 17.2726 12.2378C17.1268 12.3923 16.9275 12.4852 16.7155 12.4976C16.5035 12.5101 16.2947 12.4411 16.1318 12.3048C15.9689 12.1685 15.8643 11.9751 15.8392 11.7642L15.8333 11.6667V8.33333C15.8333 8.11232 15.9211 7.90036 16.0774 7.74408C16.2337 7.5878 16.4457 7.5 16.6667 7.5Z"
              fill={color}
            />
          </g>
          <defs>
            <clipPath id="clip0_1351_53696">
              <rect width="20" height="20" fill={color} />
            </clipPath>
          </defs>
        </svg>
      );
  }
};

export default Mingcute;
