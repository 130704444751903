import styled from 'styled-components';

export const H2 = styled.h2`
  font-weight: ${({ weight }) => weight};
  font-style: ${({ fontStyle }) => fontStyle};
  color: ${({ color }) => color};
  font-size: 48px;
  line-height: 48px;
  margin: 0;
  text-transform: uppercase;

  @media (max-width: 1439px) {
    font-size: 36px;
    line-height: 36px;
  }
  @media (max-width: 767px) {
    font-size: 26px;
    line-height: 26px;
  }
`;
