import { COLORS } from '../../../models/colors';

const Burger = ({ color, size }) => {
  if (!color) color = COLORS.WHITE;
  switch (size) {
    case 'md':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 6H23" stroke={color} strokeWidth="1.42857" stroke-linecap="round" />
          <path d="M1 12H23" stroke={color} strokeWidth="1.42857" stroke-linecap="round" />
          <path d="M1 18H23" stroke={color} strokeWidth="1.42857" stroke-linecap="round" />
        </svg>
      );
    default:
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 6H23" stroke={color} strokeWidth="1.42857" stroke-linecap="round" />
          <path d="M1 12H23" stroke={color} strokeWidth="1.42857" stroke-linecap="round" />
          <path d="M1 18H23" stroke={color} strokeWidth="1.42857" stroke-linecap="round" />
        </svg>
      );
  }
};

export default Burger;
