import { COLORS } from '../../../models/colors';

const DoorArrow = ({ color, size }) => {
  if (!color) color = COLORS.WHITE;
  switch (size) {
    case 'sm':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.1606 17.5196C15.4755 17.886 14.8017 17.9998 13.3908 17.9998H11.0057C11.0001 17.9999 10.9944 18 10.9887 18C10.542 18 10.1798 17.6378 10.1798 17.191C10.1798 16.7442 10.542 16.382 10.9887 16.382L13.3908 16.3819C14.7271 16.3819 15.0722 16.2669 15.3976 16.0929C15.6995 15.9314 15.9316 15.6993 16.093 15.3974C16.267 15.0721 16.382 14.727 16.382 13.3906L16.382 6.60918C16.382 5.27286 16.267 4.92776 16.093 4.60239C15.9316 4.3005 15.6995 4.06842 15.3976 3.90697C15.0722 3.73296 14.7271 3.61796 13.3908 3.61796L11.0058 3.61796C11.0001 3.61808 10.9944 3.61813 10.9887 3.61813C10.542 3.61813 10.1798 3.25594 10.1798 2.80915C10.1798 2.36237 10.542 2.00017 10.9887 2.00017L13.3908 2C14.8017 2 15.4755 2.11385 16.1606 2.48023C16.7445 2.79247 17.2075 3.25552 17.5198 3.83936C17.8861 4.52443 18 5.19828 18 6.60918V13.3906C18 14.8015 17.8861 15.4754 17.5198 16.1605C17.2075 16.7443 16.7445 17.2073 16.1606 17.5196Z"
            fill={color}
          />
          <path
            d="M7.72009 13.0234L9.93448 10.8091H2.80899C2.3622 10.8091 2 10.4469 2 10.0001C2 9.55331 2.3622 9.19112 2.80899 9.19112H9.93466L7.72009 6.97658C7.40416 6.66065 7.40416 6.14844 7.72009 5.83251C8.03602 5.51658 8.54825 5.51658 8.86418 5.83251L12.4597 9.42797C12.7756 9.7439 12.7756 10.2561 12.4597 10.572L8.86418 14.1675C8.54825 14.4834 8.03602 14.4834 7.72009 14.1675C7.40416 13.8516 7.40416 13.3394 7.72009 13.0234Z"
            fill={color}
          />
        </svg>
      );
    default:
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.1606 17.5196C15.4755 17.886 14.8017 17.9998 13.3908 17.9998H11.0057C11.0001 17.9999 10.9944 18 10.9887 18C10.542 18 10.1798 17.6378 10.1798 17.191C10.1798 16.7442 10.542 16.382 10.9887 16.382L13.3908 16.3819C14.7271 16.3819 15.0722 16.2669 15.3976 16.0929C15.6995 15.9314 15.9316 15.6993 16.093 15.3974C16.267 15.0721 16.382 14.727 16.382 13.3906L16.382 6.60918C16.382 5.27286 16.267 4.92776 16.093 4.60239C15.9316 4.3005 15.6995 4.06842 15.3976 3.90697C15.0722 3.73296 14.7271 3.61796 13.3908 3.61796L11.0058 3.61796C11.0001 3.61808 10.9944 3.61813 10.9887 3.61813C10.542 3.61813 10.1798 3.25594 10.1798 2.80915C10.1798 2.36237 10.542 2.00017 10.9887 2.00017L13.3908 2C14.8017 2 15.4755 2.11385 16.1606 2.48023C16.7445 2.79247 17.2075 3.25552 17.5198 3.83936C17.8861 4.52443 18 5.19828 18 6.60918V13.3906C18 14.8015 17.8861 15.4754 17.5198 16.1605C17.2075 16.7443 16.7445 17.2073 16.1606 17.5196Z"
            fill={color}
          />
          <path
            d="M7.72009 13.0234L9.93448 10.8091H2.80899C2.3622 10.8091 2 10.4469 2 10.0001C2 9.55331 2.3622 9.19112 2.80899 9.19112H9.93466L7.72009 6.97658C7.40416 6.66065 7.40416 6.14844 7.72009 5.83251C8.03602 5.51658 8.54825 5.51658 8.86418 5.83251L12.4597 9.42797C12.7756 9.7439 12.7756 10.2561 12.4597 10.572L8.86418 14.1675C8.54825 14.4834 8.03602 14.4834 7.72009 14.1675C7.40416 13.8516 7.40416 13.3394 7.72009 13.0234Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default DoorArrow;
