import React, { useState } from 'react';

import * as Styled from './styled';
import * as UI from '../index';
import { COLORS } from '../../models/colors';

const Input = ({
  placeholder,
  checkbox,
  paste,
  onChange,
  label,
  number,
  error,
  name,
  ...props
}) => {
  const [value, setValue] = useState('');

  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (e) => {
    let inputValue = e.target.value;
    if (number) {
      inputValue = e.target.value.replace(/[^0-9]/g, '');
    }
    setValue(inputValue);
    if (onChange) {
      onChange(inputValue);
    }
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  return (
    <Styled.Container>
      <Styled.Label htmlFor={name}>
        <UI.Paragraph size="small" color={COLORS.TEXT}>
          {label}
        </UI.Paragraph>
        <Styled.Input
          id={name}
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          {...props}
        />
      </Styled.Label>

      {error ? <Styled.FormErrorText>{error}</Styled.FormErrorText> : null}
    </Styled.Container>
  );
};

export default Input;
