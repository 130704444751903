export const COLORS = {
  WHITE: '#FFFFFF',
  BLACK: '#000104',
  TEXT: '#1E1F21',
  BODY: '#69696B',
  GREY: '#A6A6A6',
  STROKE: '#E4E4E4',
  BACKGROUND: '#F0F0F0',
  WHITE80: 'rgba(255, 255, 255, 0.8)',
  PINK: '#EE51AE',
  PINK_HOVER: '#E949A9',
  VIOLET: '#6F59FF',
  VIOLET_HOVER: '#5F48F5',
  LIGHT_VIOLET: '#C7BEFF',
  GRADIENT: 'linear-gradient(254.83deg, #FED500 -2.74%, #E944A6 22.87%, #5743D6 92.09%)',
  RED: '#FF3B30',
  TRANSPARENT: 'transparent'
};
