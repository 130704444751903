import gif from './Typing.gif';

const Typing = ({ size }) => {
  switch (size) {
    case 'sm':
      return <img width={32} src={gif} alt={'typing'} />;
    default:
      return <img width={32} src={gif} alt={'typing'} />;
  }
};

export default Typing;
