import styled from 'styled-components';
import { COLORS } from '../../models/colors';

export const Header = styled.header`
  z-index: 2;
  padding: 16px 100px;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 1439px) {
    padding: 16px 30px;
  }

  @media (max-width: 767px) {
    padding: 16px 15px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 1240px;
  background-color: ${({ isScrolled }) =>
    isScrolled ? COLORS.WHITE : '#00010433'};
  border-radius: 100px;
  padding: 14px 16px;
  button {
    box-shadow: ${({ isScrolled }) =>
      isScrolled ? `0 0 0 1px ${COLORS.TEXT}` : `0 0 0 1px ${COLORS.WHITE}`};
    color: ${({ isScrolled }) => (isScrolled ? COLORS.TEXT : COLORS.WHITE)};
    :hover {
    }
  }
  svg {
    flex-shrink: 0;
  }
  @media (max-width: 1439px) {
    padding: 10px 16px;
    button {
      display: none;
    }
    svg {
      width: 93px;
      height: 28px;
    }
  }

  @media (max-width: 767px) {
    padding: 10px 16px 10px 12px;
  }
`;

export const ButtonWarpper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const WrapperLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  @media (max-width: 1439px) {
    display: none;
  }
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const Burger = styled.div`
  display: none;
  @media (max-width: 1439px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    cursor: pointer;
  }
`;

export const BurgerLine = styled.div`
  border-radius: 100px;
  width: 22px;
  height: 2px;
  background: ${({ isScrolled }) => (isScrolled ? COLORS.BLACK : COLORS.WHITE)};
`;
