import React from 'react';
import * as Styled from './styled';
import { HH_TYPE } from '../../../models/types';

export const H5 = ({ children, ...props }) => {
  if (!props.type) props.type = HH_TYPE.SEMIBOLD;

  if (!props.weight) props.weight = props.type.weight;
  if (!props.fontStyle) props.fontStyle = props.type.style;

  return <Styled.H5 {...props}>{children}</Styled.H5>;
};

export default H5;
