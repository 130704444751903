import styled from 'styled-components';
import { ROUND, BACKGROUND_TYPE } from '../../models/types';

export const Container = styled.div`
  position: relative;
  z-index: 1;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 100px;
  background: ${({ background }) => background};
  background-repeat: no-repeat;

  ${({
  type,
  background,
  second_background,
  tablet_background,
  mobile_background,
}) => {
    if (type === BACKGROUND_TYPE.DOUBLE) {
      return `
        background: ${background}, ${second_background};
        background-position: left, right;
        background-repeat: no-repeat, no-repeat;
        @media (max-width: 1439px) {
          background: ${tablet_background};
          background-position: center;
          background-repeat: no-repeat;
          background-position-y: 60%;
          background-size: cover;
        }
        @media (max-width: 767px) {
          background: ${mobile_background};
          background-position-y: 50%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position-x: 100%;
        }
       
      `;
    }
    return '';
  }}

  border-radius: ${({ rounded }) => {
    switch (rounded) {
      case ROUND.TOP:
        return '40px 40px 0 0';
      case ROUND.BOTTOM:
        return '0 0 40px 40px';
      case ROUND.ALL:
        return '40px';
      case ROUND.NONE:
        return '0';
      default:
        return '0';
    }
  }};

  @media (max-width: 1439px) {
    max-width: unset;
    max-width: 100%;
    padding: 0 24px;
  }

  @media (max-width: 767px) {
    padding: 0 12px;
    border-radius: ${({ rounded }) => {
    switch (rounded) {
      case ROUND.TOP:
        return '30px 30px 0 0';
      case ROUND.BOTTOM:
        return '0 0 30px 30px';
      case ROUND.ALL:
        return '30px';
      case ROUND.NONE:
        return '0';
      default:
        return '0';
    }
  }};
  }
`;
