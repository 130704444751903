import styled from 'styled-components';
import { COLORS } from '../../models/colors';

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: 'transparent';
  border-radius: 100px;
  padding: 4px 10px;
  border: ${({ light }) =>
    light ? `1px solid ${COLORS.WHITE80}` : `1px solid ${COLORS.BODY}`};

  width: fit-content;

  @media (max-width: 1439px) {
    padding: 4px 16px;
  }

  @media (max-width: 767px) {
    padding: 4px 12px;
  }
`;
